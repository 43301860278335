import axios from 'axios'
import { SessionSetting } from '@/Types/SessionSetting'
import { useGlobalStore } from '@/Store/useGlobalStore'
import {
  ProductResource,
  ProductResourceExpansionKeys,
  ProductType,
  ProductUnavailabilityStatusType,
} from '@/Types/Product'
import ProductVM, { ProductVMType } from '@/ViewModels/ProductVM'
import { ProductAttributeGroupResource } from '@/Types/AttributeGroup'
import { ProductAttributeResource } from '@/Types/ProductAttribute'
import useLangStore from '@/Store/useLangStore'
import { redirectToError } from '@/Helpers/Redirect'

export type GetProductsParams = {
  per_page?: number | string
  idWhitelist?: number[]
  product_attribute_ids?: number[]
  page?: number
  category?: string | number
  hide_out_of_stock?: boolean
  types?: ProductType[]
  no_minimum_quantity?: boolean
}

export const getProducts = async <
  T extends ProductResourceExpansionKeys,
>(config?: {
  data?: GetProductsParams
  expandArray: readonly T[]
}) => {
  const globalStore = useGlobalStore()
  const langStore = useLangStore()

  const currentCountryId = globalStore.sessionSettings
    ? globalStore.sessionSettings.find((item) => item.id === 'CURRENT_COUNTRY')
        ?.value
    : null

  const countryObject = globalStore.countries
    ? globalStore.countries.find((c) => c.id === currentCountryId)
    : null

  const params: Record<string, unknown> = {
    'per-page': config?.data?.per_page ?? 18,
    'filter[is_active]': true,
  }

  if (config?.expandArray.length) {
    params['expand'] = config.expandArray.join(',')
  }

  if (countryObject) {
    params['filter[id][nin][]'] = countryObject.blacklisted_products.map(
      (p) => p.id,
    )
  }

  let idWhitelist = config?.data?.idWhitelist
  if (config?.data?.product_attribute_ids?.length) {
    const allowedIdsFromProductAttributes = (
      await getProductAttributes({ ids: config.data.product_attribute_ids })
    )
      .map((a) => a.products.map((p) => p.id))
      .flat()

    if (!idWhitelist) idWhitelist = allowedIdsFromProductAttributes
    else {
      idWhitelist = idWhitelist.filter((id) =>
        allowedIdsFromProductAttributes.includes(id),
      )
      if (!idWhitelist.length) return { data: [], page_count: 0, page: 1 }
    }
  }
  if (idWhitelist) params['filter[id][in][]'] = idWhitelist

  if (config?.data?.page) {
    params['page'] = config.data.page
  }
  if (config?.data?.category) {
    params['filter[category_id]'] = config?.data?.category
  }
  if (config?.data?.hide_out_of_stock) {
    params['filter[unavailability_status]'] = 'NULL'
  }

  const types = config?.data?.types ?? [
    ProductType.REGULAR,
    ProductType.SAMPLE_SET,
    ProductType.COLLECTION_BOX,
  ]
  if (types.length === 1) {
    params['filter[type]'] = types[0]
  } else if (types.length > 1) {
    params['filter[type][in]'] = types
  }

  if (config?.data?.no_minimum_quantity) {
    params['filter[minimum_quantity]'] = 'NULL'
  }

  if (
    config?.data &&
    'is_pro' in config.data &&
    typeof config.data.is_pro === 'boolean'
  ) {
    params['filter[is_pro]'] = config.data.is_pro
  }

  const response = await axios.get(`/${langStore.getLang()}/products`, {
    params,
  })

  return {
    data: (response.data as Array<ProductResource<T>>).map(
      ProductVM.createFrom,
    ),
    page_count: parseInt(response.headers['x-pagination-page-count'] as string),
    page: parseInt(response.headers['x-pagination-current-page'] as string),
  }
}

export const getProductById = async <T extends ProductResourceExpansionKeys>(
  id: number,
  config: {
    params?: Record<string, unknown>
    expandArray: readonly T[]
  },
) => {
  const lang = useLangStore().getLang()

  const response = await axios.get(`/${lang}/products/${id}`, {
    params: {
      ...config.params,
      expand: (config.expandArray ?? []).join(','),
    },
  })

  if (response.data) {
    return ProductVM.createFrom(response.data as ProductResource<T>)
  } else {
    redirectToError()
    return null
  }
}

export const getProductBySlug = async <T extends ProductResourceExpansionKeys>(
  slug?: string | null,
  expandArray: readonly T[] = [],
) => {
  const lang = useLangStore().getLang()

  const params: Record<string, unknown> = { 'filter[is_active]': true }
  if (slug) params['filter[slug]'] = slug
  if (expandArray) params.expand = expandArray.join(',')

  const response = await axios.get<ProductResource<T>[]>(`/${lang}/products`, {
    params,
  })

  if (!response.data?.length) {
    redirectToError()
    return null
  }

  return ProductVM.createFrom(response.data[0])
}

export const getAttributeGroups = async () => {
  const lang = useLangStore().getLang()

  const expandArray = ['product_attributes'] as const
  const expand = expandArray.join(',')

  const response = await axios.get(
    `/${lang}/product-attribute-groups?per-page=50&expand=${expand}`,
  )

  return response.data as ProductAttributeGroupResource<
    (typeof expandArray)[number]
  >[]
}

export const getProductAttributes = async (data: { ids?: number[] }) => {
  const lang = useLangStore().getLang()

  const expandArray = ['products'] as const
  const expand = expandArray.join(',')

  const params: Record<string, unknown> = {
    'per-page': 50,
    expand,
  }

  if (data?.ids?.length) {
    params['filter[id][in][]'] = data.ids
  }

  const response = await axios.get(`/${lang}/product-attributes`, {
    params,
  })

  return response.data as ProductAttributeResource<
    (typeof expandArray)[number]
  >[]
}

export const postWaitingListSubmission = (
  id: number,
  email: string,
  recaptcha: string,
) => {
  const lang = useLangStore().getLang()

  return axios
    .post(`/${lang}/waiting-list-submissions`, {
      product_id: id,
      email,
      recaptcha,
    })
    .then((response) => response.data)
}

export const getProductCategories = () => {
  const lang = useLangStore().getLang()

  return axios
    .get(`/${lang}/product-categories`)
    .then((response) => response.data)
}

export const postNewsletter = (data: {
  email: string
  recaptcha: string
  accept_newsletter: boolean
  accept_terms: boolean
}) => {
  return axios
    .post(`/${useLangStore().getLang()}/pop-up-newsletter-submissions`, data)
    .then((response) => response.data)
}

export const postProSignup = async () => {
  return await axios.post(`/${useLangStore().getLang()}/pro-user-submissions`)
}

export const isProductDisabled = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) => {
  const globalStore = useGlobalStore()
  const country = globalStore.sessionSettings
    ? (globalStore.sessionSettings.find(
        (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
      )?.value as number | undefined)
    : null
  return (
    product.unavailability_status ||
    !product.is_active ||
    !product.lowestAvailablePrice ||
    (country &&
      product.blacklisted_countries.map((c) => c.id).includes(country))
  )
}

export const isProductDisabledExceptOutOfStock = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) => {
  return (
    isProductDisabled(product) &&
    product.unavailability_status !==
      ProductUnavailabilityStatusType.OUT_OF_STOCK
  )
}

export const isProductDisabledDueToBeingOutOfStock = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) => {
  const globalStore = useGlobalStore()
  const country = globalStore.sessionSettings
    ? (globalStore.sessionSettings.find(
        (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
      )?.value as number | undefined)
    : null

  return (
    product.unavailability_status ===
      ProductUnavailabilityStatusType.OUT_OF_STOCK &&
    product.is_active &&
    product.lowestAvailablePrice &&
    !(
      country &&
      product.blacklisted_countries.map((c) => c.id).includes(country)
    )
  )
}

export const isProductDisabledDueToBeingComingSoon = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) => {
  const globalStore = useGlobalStore()
  const country = globalStore.sessionSettings
    ? (globalStore.sessionSettings.find(
        (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
      )?.value as number | undefined)
    : null

  return (
    product.unavailability_status ===
      ProductUnavailabilityStatusType.COMING_SOON &&
    product.is_active &&
    product.lowestAvailablePrice &&
    !(
      country &&
      product.blacklisted_countries.map((c) => c.id).includes(country)
    )
  )
}
