<template>
  <img
    v-lazy="{ src, srcset, sizes }"
    v-bind="{ ...$attrs, ...imgSizeAttributes }"
    :alt="alt"
    src="#"
  />
</template>

<script setup lang="ts">
import { createCloudflareImageUrl } from '@/Helpers/StaticContent'
import { computed } from 'vue'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { MAX_MOBILE_PX, STATIC_CONTENT_PATH_PREFIX } from '@/Helpers/Constants'
import { isSvgSrc } from '@/Helpers/Global'
import toSorted from 'array.prototype.tosorted'

type ImgSize = { height: number } & (
  | { width: number } /** for fixed width images */
  /** for responsive images, where maxWidth is the maximum with image can take up,
   * and displayWidth is a string denoting the width of the image in the viewport
   * @example { maxWidth: 600, displayWidth: 'calc(100vw - 100px)' }
   * */
  | { maxWidth: number; displayWidth: string }
)

const props = defineProps<{
  src: string
  size: ImgSize | { mobile: ImgSize; desktop: ImgSize }
  alt: string
}>()

const { isMobile } = useScreenBreakpoints()

const computedSize = computed(() =>
  'height' in props.size
    ? props.size
    : isMobile.value
      ? props.size.mobile
      : props.size.desktop,
)

const imgSizeAttributes = computed(() => ({
  width:
    'width' in computedSize.value
      ? computedSize.value.width
      : computedSize.value.maxWidth,
  height: computedSize.value.height,
}))

const srcPathname = computed(() => {
  try {
    /** if src is a full url, use pathname */
    return new URL(props.src).pathname
  } catch (e) {
    /** if src is a relative path, assume it's relative to the static content path */
    return `${STATIC_CONTENT_PATH_PREFIX}${props.src}`
  }
})

const src = computed(() =>
  createCloudflareImageUrl({
    image: srcPathname.value,
    width:
      'width' in computedSize.value
        ? computedSize.value.width
        : computedSize.value.maxWidth,
  }),
)

const isSvg = computed(() => isSvgSrc(srcPathname.value))

const srcset = computed(() => {
  if (isSvg.value) return null

  const sizes = (
    'height' in props.size
      ? [props.size]
      : [props.size.mobile, props.size.desktop]
  )
    .map((size) => ({
      height: size.height,
      width: 'width' in size ? size.width : size.maxWidth,
    }))
    /** include both the original size and its double for high DPI screens
     * @see https://developers.cloudflare.com/images/transform-images/make-responsive-images/
     */
    .map((size) => [size, { height: size.height * 2, width: size.width * 2 }])
    .flat()

  return toSorted(sizes, (a, b) => a.width - b.width)
    .map((size) => {
      const src = createCloudflareImageUrl({
        image: srcPathname.value,
        width: size.width,
      })
      return `${src} ${size.width}w`
    })
    .join(',\n')
})

const sizes = computed(() => {
  if ('height' in props.size || isSvg.value) return

  const mobileWidth =
    'width' in props.size.mobile
      ? props.size.mobile.width + 'px'
      : props.size.mobile.displayWidth
  const desktopWidth =
    'width' in props.size.desktop
      ? props.size.desktop.width + 'px'
      : props.size.desktop.displayWidth

  return `(max-width: ${MAX_MOBILE_PX}px) ${mobileWidth}, ${desktopWidth}`
})
</script>
