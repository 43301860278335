import { __ } from './i18n'
import { useLoginStore } from '@/Store/useLoginStore'

type InputType =
  | 'text'
  | 'tel'
  | 'email'
  | 'select'
  | 'switch'
  | 'file'
  | 'country'
  | 'full_name'
  | 'street'
  | 'store'
  | 'currency'
  | 'state'

export type Input = {
  type: InputType
  label: string
  name: string
  description?: string
  legal?: boolean
  readonly?: boolean
  values?: [any]
  value?: any
  showDescription?: boolean
}

export const getSignUpInputs = (): Input[] => [
  {
    type: 'email',
    label: __('forms', 'Email'),
    name: 'email',
  },
]

export const getProfileDetailsInputs = (): Input[] => [
  {
    type: 'full_name',
    label: __('forms', 'Full name'),
    name: 'first_name',
  },
  {
    type: 'email',
    label: __('forms', 'Email'),
    name: 'email',
  },
  {
    type: 'tel',
    label: __('forms', 'Phone'),
    name: 'phone',
  },
]

export const getProfileCompanyDetailsInputs = (): Input[] => [
  {
    type: 'text',
    label: __('forms', 'Company name'),
    name: 'company_name',
  },
  {
    type: 'country',
    label: __('forms', 'Country'),
    name: 'country',
  },
  {
    type: 'text',
    label: __('forms', 'Registration number'),
    name: 'registration_number',
  },
  {
    type: 'text',
    label: __('forms', 'VAT ID'),
    name: 'vat_id',
  },
  {
    type: 'file',
    label: __('forms', 'VAT Certificate'),
    name: 'vat_certificate',
  },
]

export const getProfileMarketingInputs = (): Input[] => [
  {
    type: 'switch',
    label: __('forms', 'Newsletter Subscription'),
    name: 'receive_emails',
  },
]

export const getLoginInputs = () => [
  {
    type: 'email',
    label: __('forms', 'Email'),
    name: 'email',
    value: null,
  },
  {
    type: 'password',
    label: __('forms', 'Password'),
    name: 'password',
    value: null,
  },
]

export const getConfirmRegistrationInputs = () => [
  {
    type: 'password',
    label: __('forms', 'Password'),
    name: 'password',
  },
  {
    type: 'password',
    label: __('forms', 'Confirm password'),
    name: 'password_repeat',
  },
]

export const getAcceptSocialInputs = (): Array<{
  type: string
  label: string
  name: string
  value?: string
}> => [
  {
    type: 'email',
    label: __('forms', 'Email'),
    name: 'email',
  },
]

export const getCustomerInputs = (): Input[] => {
  const loginStore = useLoginStore()
  const isLegalEntityWithVerifiedVat = loginStore.isLegalEntityWithVerifiedVat

  return [
    {
      type: 'text',
      label: __('forms', 'First name') + ' *',
      name: 'first_name',
      legal: false,
      readonly: false,
    },
    {
      type: 'text',
      label: __('forms', 'Last name') + ' *',
      name: 'last_name',
      legal: false,
      readonly: false,
    },
    {
      type: 'text',
      label: __('forms', 'Company name') + ' *',
      name: 'company_name',
      legal: true,
      readonly: isLegalEntityWithVerifiedVat,
    },
    {
      type: 'email',
      label: __('forms', 'Email') + ' *',
      name: 'email',
      legal: false,
      readonly: false,
    },
    {
      type: 'tel',
      label: __('forms', 'Phone') + ' *',
      name: 'phone',
      legal: false,
      readonly: false,
    },
    {
      type: 'text',
      label: __('forms', 'Registration nr') + ' *',
      name: 'reg_no',
      legal: true,
      readonly: isLegalEntityWithVerifiedVat,
    },
    {
      type: 'text',
      label: __('forms', 'VAT nr') + ' *',
      name: 'vat_no',
      legal: true,
      readonly: isLegalEntityWithVerifiedVat,
    },
  ]
}

export const getAddressInputs = (): Input[] => [
  {
    type: 'country',
    label: __('global', 'Country') + ' *',
    name: 'country_code',
    readonly: true,
  },
  {
    type: 'state',
    label: __('forms', 'State/region'),
    name: 'state_region',
    readonly: false,
  },
  {
    type: 'text',
    label: __('forms', 'City') + ' *',
    name: 'city',
    readonly: false,
  },
  {
    type: 'text',
    label: __('forms', 'Postal code') + ' *',
    name: 'postal_code',
    readonly: false,
  },
  {
    type: 'text',
    label: __('forms', 'Street') + ' *',
    name: 'street',
    readonly: false,
  },
  {
    type: 'text',
    label: __('forms', 'Street number'),
    name: 'street_no',
    readonly: false,
  },
  {
    type: 'text',
    label: __('forms', 'Flat number'),
    name: 'flat_no',
    readonly: false,
  },
]

export const getBillingAddressInputs = (): Input[] => {
  const inputs = getAddressInputs()
  const countryInput = inputs.find((i) => i.name === 'country_code')
  if (countryInput) countryInput.readonly = false
  return inputs
}

export const getProfileAddressInputs = (): Input[] => [
  {
    type: 'text',
    label: __('forms', 'Address title'),
    name: 'title',
    value: null,
  },
  {
    type: 'country',
    label: __('global', 'Country') + ' *',
    name: 'country_code',
    value: null,
  },
  {
    type: 'state',
    label: __('forms', 'State/region'),
    name: 'state_region',
    readonly: false,
  },
  {
    type: 'text',
    label: __('forms', 'City') + ' *',
    name: 'city',
    value: null,
  },
  {
    type: 'street',
    label: __('forms', 'Street') + ' *',
    name: 'street',
    value: null,
  },
  {
    type: 'text',
    label: __('forms', 'Flat number'),
    name: 'flat_no',
    value: null,
  },
  {
    type: 'text',
    label: __('forms', 'Postal code') + ' *',
    name: 'postal_code',
    value: null,
  },
]

export const getBillingTypeInputs = (): Input[] => [
  {
    type: 'text',
    label: __('forms', 'Method Title'),
    name: 'title',
    value: null,
  },
  {
    type: 'store',
    label: __('dropshipping-stores', 'Store'),
    name: 'store_id',
    value: null,
  },
]

export const getBillingInfoInputs = (): Input[] => [
  {
    type: 'full_name',
    label: __('forms', 'Full name'),
    name: 'full_name',
    readonly: true,
  },
  {
    type: 'text',
    label: __('forms', 'Company name'),
    name: 'company_name',
    readonly: true,
  },
  {
    type: 'country',
    label: __('forms', 'Country'),
    name: 'country',
    readonly: true,
  },
  {
    type: 'state',
    label: __('forms', 'State/region'),
    name: 'state_region',
  },
  {
    type: 'text',
    label: __('forms', 'City'),
    name: 'city',
  },
  {
    type: 'street',
    label: __('forms', 'Street; Number'),
    name: 'street',
  },
  {
    type: 'text',
    label: __('forms', 'Flat number'),
    name: 'flat_no',
  },
  {
    type: 'text',
    label: __('forms', 'Postal code'),
    name: 'postal_code',
  },
]

export const getBillingCurrencyInputs = (): Input[] => [
  {
    type: 'currency',
    label: __('forms', 'payment-currency-label'),
    name: 'currency_code',
    value: null,
    description: __('forms', 'currency-dropdown-description'),
    showDescription: true,
  },
]
