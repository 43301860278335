<template>
  <div class="update-profile">
    <h4>{{ __('profile', 'Provide email address') }}</h4>
    <form v-if="inputs" action="" @submit.prevent="updateProfile">
      <div
        v-for="item in inputs"
        :key="item.name"
        class="input-element"
        :class="{ error: findErrorField(errors, item.name) }"
      >
        <div class="wrap">
          <input
            :id="item.name"
            v-model="item.value"
            placeholder=" "
            :type="item.type"
          />
          <label :for="item.name">{{ item.label }}</label>
        </div>

        <div v-if="errors" class="errors">
          <div v-for="i in errors" :key="i.field">
            <p v-if="i.field === item.name">{{ i.message }}</p>
          </div>
        </div>
      </div>
      <SignUpCheckboxes
        :errors="errors"
        :newsletter="false"
        :terms="terms"
        @update-terms="updateTerms"
      />
      <div class="button-wrap">
        <button class="btn btn-primary" type="submit">
          <span>{{ __('profile', 'Update') }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { getAcceptSocialInputs } from '@/Helpers/Forms'
import { findErrorField } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import { useLoginStore } from '@/Store/useLoginStore'
import { patchProfile } from '@/Api/Profile'
import { setErrors } from '@/Helpers/Errors'
import SignUpCheckboxes from './SignUpCheckboxes.vue'

const emit = defineEmits<{
  (e: 'switchToSuccess', payload: { title: string; description: string }): void
}>()

const loginStore = useLoginStore()

const inputs = ref(getAcceptSocialInputs())
const terms = ref(false)
const errors = ref()

onMounted(() => {
  inputs.value.forEach((item) => {
    if (!loginStore.profile) return
    if (loginStore.profile[item.name as keyof typeof loginStore.profile]) {
      item.value = loginStore.profile[
        item.name as keyof typeof loginStore.profile
      ] as string
    }
  })
})

const updateProfile = async () => {
  errors.value = null
  const data: any = {}

  inputs.value.forEach((item: any) => {
    if (item.value) {
      data[item.name] = item.value
    }
  })

  data.terms_accepted = terms.value

  await patchProfile(data).catch((error) => {
    errors.value = setErrors(error)
  })

  if (!errors.value) {
    emit('switchToSuccess', {
      title: __('sign-up', 'Thank you for signing up!'),
      description: __(
        'sign-up',
        "We've sent you confirmation link to your e-mail address",
      ),
    })

    inputs.value.forEach((item: any) => {
      item.value = null
    })
  }
}

const updateTerms = (value: boolean) => {
  terms.value = value
}
</script>

<style lang="scss">
.update-profile {
  h4 {
    margin-bottom: 2rem;
  }
}
</style>
