<template>
  <div v-if="requiredRequestsDone" class="content-container">
    <Header
      v-if="!checkIfIncludesRoute(routesWithoutGlobalHeader, route.name)"
    />
    <div
      class="global-message-banner"
      :class="{
        'has-header': !checkIfIncludesRoute(
          routesWithoutGlobalHeader,
          route.name,
        ),
      }"
      v-html="__('home', 'Header global message')"
    ></div>
    <div class="site-content"><router-view /></div>
    <Footer
      v-if="!checkIfIncludesRoute(routesWithoutGlobalFooter, route.name)"
    />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { onMounted, watch, ref } from 'vue'
import Header from './Components/Header.vue'
import Footer from './Components/Footer.vue'
import { initGoogleLogo } from '@/Helpers/GoogleStructuredData'
import { useGlobalStore } from './Store/useGlobalStore'
import { removeOrder } from '@/Helpers/Checkout'
import { useLoginStore } from './Store/useLoginStore'
import { useCartStore } from './Store/useCartStore'
import { __ } from '@/Helpers/i18n'

const globalStore = useGlobalStore()
const loginStore = useLoginStore()
const cartStore = useCartStore()
const route = useRoute()

//TODO: refactor - maybe use router meta
const routesWithoutGlobalHeaderAndFooter = [
  'studio',
  'profile',
  'profile-dashboard',
  'view-order',
  'dropshipping-stores',
  'dropshipping-connect-store',
  'dropshipping-view-store',
  'ds-product-details',
  'ds-product-choose',
  'ds-product-view',
  'ds-choose-mockups',
  'my-products',
  'payment-method-form',
  'collection-box-customization',
  'collection-box-overview',
  'collection-box-product-design',
]

const routesWithoutGlobalHeader = [
  ...routesWithoutGlobalHeaderAndFooter,
  'product-open',
]

const routesWithoutGlobalFooter = [
  ...routesWithoutGlobalHeaderAndFooter,
  'sample-sets-widget',
  'store-connections',
]

watch(route, async (n) => {
  await loginStore.setProfile()
  n.name !== 'checkout'
    ? await removeOrder()
    : await cartStore.initializeCartIfAuthenticated()
})

const requiredRequestsDone = ref(false)

onMounted(async () => {
  ;(document.querySelector('body') as HTMLElement).classList.remove('preload')
  fixForIphoneHeight()

  window.addEventListener('scroll', () => {
    fixForIphoneHeight()
  })

  window.addEventListener('resize', () => {
    fixForIphoneHeight()
  })

  initGoogleLogo()
  await Promise.all([
    cartStore.initializeCartIfAuthenticated(),
    globalStore.setTranslations(),
    globalStore.setCountries(),
    globalStore.setSettings(),
    globalStore.setSessionSettings(),
    loginStore.setProfile(),
  ])

  requiredRequestsDone.value = true
  initHeartBeat()
})

const fixForIphoneHeight = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const checkIfIncludesRoute = (
  array: string[],
  route: ReturnType<typeof useRoute>['name'],
) =>
  typeof route === 'string' &&
  array.findIndex((item) => route.includes(item)) > -1

const initHeartBeat = () => {
  setInterval(() => globalStore.setSessionSettings(), 20000)
}
</script>

<style lang="scss" scoped>
.content-container {
  &:has(.global-message-banner) {
    .site-content {
      padding-top: 0;
      margin-top: 6rem;
    }
  }

  .site-content {
    margin-top: 6.4rem;
  }
  &:has(.site-header) {
    .site-content {
      margin-top: 0;
    }
  }
}

.global-message-banner {
  padding: 1.1rem 0.6rem;
  background: $light-gray;
  color: $black;
  text-align: center;
  font-size: 12px;

  &:deep(a) {
    color: $black;
    text-decoration: underline;
  }

  &.has-header {
    margin-top: 6.5rem;
  }
}
</style>
