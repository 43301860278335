<template>
  <header class="site-header" :class="{ scrolled: headerScrolled }">
    <div class="container">
      <router-link class="logo" :to="`/${langStore.getLang()}`">
        <OptimizedImg
          alt="Logo"
          :size="{ height: 28, width: 170 }"
          src="logo.svg"
        />
      </router-link>
      <Menu
        v-if="sessionSettings && countries"
        :country="getCurrentSessionValue('CURRENT_COUNTRY').title"
        :currency="getCurrentSessionValue('CURRENT_COUNTRY').currency.name"
        :open="mobileMenuOpen"
        :open-preferences="openPreferences"
        @close="mobileMenuOpen = false"
        @open-sign-up="(data) => (authenticationData = data)"
        @toggle-preferences="togglePreferencesMenu"
      />
      <div class="right-menu">
        <div class="options" :class="{ 'logged-in': loginStore.userLoggedIn }">
          <div
            v-if="sessionSettings && countries"
            v-click-away="closePreferencesMenu"
            class="preferences"
          >
            <p v-if="currentCountry" class="header-bubble">
              <span>{{ currentCountry }}</span>
            </p>
            <a href="#" @click.prevent="togglePreferencesMenu">
              <SvgIcon name="globe" />
            </a>
            <PreferencesDropdown
              v-if="openPreferences"
              @close="closePreferencesMenu"
            />
          </div>
          <ConditionalRouterLink
            v-if="loginStore.userLoggedIn"
            :active="loginStore.userLoggedIn"
            class="profile"
            :to="toProfile"
            @disabled-click="
              () =>
                (authenticationData = {
                  redirectUrl: toProfile,
                  isLogin: false,
                })
            "
          >
            <SvgIcon name="profile" />
          </ConditionalRouterLink>
          <ConditionalRouterLink
            v-if="cartStore.cart && loginStore.userLoggedIn"
            :active="loginStore.userLoggedIn"
            class="cart"
            :to="toCart"
            @disabled-click="
              () =>
                (authenticationData = { redirectUrl: toCart, isLogin: false })
            "
          >
            <p v-if="cartStore.cartItemQuantity" class="header-bubble">
              <span>{{ cartStore.cartItemQuantity }}</span>
            </p>
            <SvgIcon name="cart" />
          </ConditionalRouterLink>
        </div>
        <div v-if="!loginStore.userLoggedIn" class="login-btn-row">
          <button
            class="btn btn-secondary type-login"
            @click="authenticationData = { isLogin: true }"
          >
            {{ __('global', 'Sign in') }}
          </button>
          <button
            class="btn btn-primary type-login"
            @click="authenticationData = { isLogin: false }"
          >
            {{ __('global', 'Register') }}
          </button>
        </div>
      </div>
      <div class="menu-toggle">
        <a
          v-if="cartStore.cart || !loginStore.userLoggedIn"
          aria-label="Menu toggle"
          class="burger"
          :class="{ visible: !mobileMenuOpen }"
          href="#"
          @click.prevent="mobileMenuOpen = true"
        >
          <p v-if="cartStore.cartItemQuantity" class="header-bubble">
            <span>{{ cartStore.cartItemQuantity }}</span>
          </p>
          <SvgIcon name="burger" />
        </a>
        <a
          aria-label="Menu toggle"
          class="exit"
          :class="{ visible: mobileMenuOpen }"
          href="#"
          @click.prevent="mobileMenuOpen = false"
        >
          <SvgIcon name="exit" />
        </a>
      </div>
    </div>
    <SignUpModal
      v-if="authenticationData"
      :login="authenticationData.isLogin"
      open
      :redirect-url="authenticationData.redirectUrl"
      @close="authenticationData = null"
    />
  </header>
</template>

<script setup lang="ts">
import PreferencesDropdown from '@/Partials/PreferencesDropdown.vue'
import Menu from '@/Partials/Menu.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { SessionSetting } from '@/Types/SessionSetting'
import { CountryResourceMainFields } from '@/Types/Country'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'
import { getCurrentSessionValue } from '@/Helpers/Global'
import Swal from 'sweetalert2'
import { __ } from '@/Helpers/i18n'
import { useLoginStore } from '@/Store/useLoginStore'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useCartStore } from '@/Store/useCartStore'
import { HeaderSetting } from '@/Types/Setting'
import useLangStore from '@/Store/useLangStore'
import SignUpModal from './SignUpModal.vue'
import { withLang } from '@/routes'
import ConditionalRouterLink from '@/Partials/ConditionalRouterLink.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'

const loginStore = useLoginStore()
const globalStore = useGlobalStore()
const cartStore = useCartStore()
const langStore = useLangStore()
const route = useRoute()

const mobileMenuOpen = ref(false)
const authenticationData = ref<{
  isLogin: boolean
  redirectUrl?: RouteLocationRaw
} | null>(null)
const openPreferences = ref(false)
const lastScrollTop = ref(0)
const signUpOpen = ref(false)

onMounted(async () => {
  activateScroll()

  window.addEventListener('scroll', activateScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', activateScroll)
})

watch(route, () => (mobileMenuOpen.value = false))

const sessionSettings = computed(() => globalStore.sessionSettings)
const countries = computed(() => globalStore.countries!)

const headerSettings = computed(
  () =>
    globalStore.getSettingByKey('headersetting') as HeaderSetting | undefined,
)

const currentCountry = computed(
  () =>
    countries.value.find(
      (item: CountryResourceMainFields) =>
        item.id ===
        sessionSettings.value?.find(
          (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
        )?.value,
    )?.code,
)

const togglePreferencesMenu = () => {
  if (
    cartStore.cart &&
    cartStore.cart.cart_items.length &&
    openPreferences.value === false
  ) {
    Swal.fire({
      position: 'top-end',
      customClass: { popup: 'info-popup' },
      title: __('header', 'Changing the country will delete the shopping bag'),
      showConfirmButton: false,
      timer: 5000,
      toast: true,
    })
  }
  openPreferences.value = !openPreferences.value
}

const closePreferencesMenu = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  )
  if (vw >= 1200) {
    openPreferences.value = false
  }
}

const headerScrolled = ref(false)

const activateScroll = () => {
  const filtersOpen = document.querySelector('.products .filters.open')
  headerScrolled.value =
    !(signUpOpen.value || mobileMenuOpen.value || filtersOpen) &&
    window.scrollY >= Math.max(80, lastScrollTop.value)

  lastScrollTop.value = window.scrollY
}

const toProfile = computed(() => withLang({ name: 'profile' }))

const toCart = computed(() => withLang({ name: 'cart' }))
</script>

<style lang="scss">
.site-header {
  padding: 2rem 0;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  transition: 0.3s all;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  background: $white;
  @media (min-width: 1200px) {
    padding: 0;
  }

  &.scrolled {
    transform: translateY(-101%);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 1200px) {
      justify-content: flex-start;
    }
  }

  .logo {
    max-width: 170px;
    width: 100%;
    @media (min-width: 1200px) {
      max-width: 222px;
      padding-right: 2.8rem;
      border-right: 1px solid $light-gray;
      margin-right: 1.8rem;
    }

    .mobile {
      @media (min-width: 1200px) {
        display: none;
      }
    }

    .desktop {
      display: none;
      @media (min-width: 1200px) {
        display: block;
      }
    }

    img {
      width: 100%;
    }
  }

  .right-menu {
    display: none;
    @media (min-width: 1200px) {
      align-items: center;
      display: inline-flex;
      margin-left: auto;
    }
  }

  .options {
    display: flex;
    align-items: center;
    padding: 0.6rem 0;
    margin-right: 0.4rem;

    &.logged-in {
      padding: 0.6rem 2.8rem 0.6rem 0;
      margin-right: 2.8rem;

      &:not(:last-child) {
        border-right: 1px solid $light-gray;
      }
    }
  }

  .menu-toggle {
    margin-right: 0.8rem;
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .burger,
  .exit {
    color: $black;
    display: none;
    position: relative;

    &.visible {
      display: block;
      @media (min-width: 1200px) {
        display: none;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .profile,
  .cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
    margin-bottom: 2.4rem;
    cursor: pointer;

    @media (min-width: 1200px) {
      margin-bottom: 0;
    }

    &:hover {
      color: $orange;
    }

    > div {
      display: flex;
      align-items: center;
      position: relative;
    }

    svg {
      width: 20px;
      height: 20px;
      transform: none;
      margin-right: 1.2rem;
      @media (min-width: 1200px) {
        margin-right: 0;
      }

      &.chevron {
        width: 9px;
        height: 9px;
        transform: rotate(-90deg);
        margin-right: 0;
      }
    }
  }

  .btn {
    margin-right: 2rem;
    @media (min-width: 1200px) {
      margin-right: 0;
    }
  }

  .preferences {
    padding: 2.4rem 0;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    position: relative;
    @media (min-width: 1200px) {
      margin: 0 2.8rem 0 1.2rem;
      padding: 0;
      border: none;
    }

    &:hover {
      > a {
        color: $orange;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    a {
      color: $black;
    }

    svg {
      cursor: pointer;
      transition: 0.3s all;
    }

    > a {
      > svg {
        width: 20px;
        height: 20px;
      }
    }

    .chevron {
      width: 12px;
      height: 12px;
    }

    .selected {
      color: $black;
      font-size: 1.4rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      span {
        &:first-child {
          font-size: 1.6rem;
          font-weight: 400;
        }

        &:last-child {
          margin-right: 2.8rem;
          font-weight: 400;
          color: $gray-10;
        }
      }

      a {
        position: relative;
      }
    }
  }

  .cart {
    position: relative;
    @media (min-width: 1200px) {
      margin-left: 2.8rem;
    }

    .header-bubble {
      @media (max-width: 1199px) {
        right: -1.2rem;
        top: 0.4rem;
      }
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .login-btn-row {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    button {
      padding: 1.4rem 1.6rem;
      white-space: nowrap;
    }
  }
}
</style>
