import axios from 'axios'
import {
  getLocales,
  getSiteTreeSections,
  getTranslations,
  getSessionSettings,
  getCountries,
  getSettings,
} from '@/Api/Global'
import { defineStore } from 'pinia'
import { Locale } from '@/Types/Locale'
import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'
import { SessionSetting } from '@/Types/SessionSetting'
import { CountryResource } from '@/Types/Country'
import { Setting } from '@/Types/Setting'
import { setErrors } from '@/Helpers/Errors'
import useLangStore from './useLangStore'
import { listToTree } from '@/Helpers/TreeHelper'
import { PRO_PRODUCTS_ROUTE_NAME } from '@/Helpers/Constants'
import { __ } from '@/Helpers/i18n'

export const useGlobalStore = defineStore('global', {
  state: (): {
    locales: Locale[] | null
    siteTree: SiteTreeSectionResource<'products'>[] | null
    sessionSettings: SessionSetting[] | null
    countries:
      | CountryResource<
          'currency' | 'default_locale' | 'blacklisted_products'
        >[]
      | null
    errors: any
    settings: Setting[] | null
    productsUrl: string | null
    servicesUrl: string | null
    faqUrl: string | null
    aboutUsUrl: string | null
    translations: Record<string, Record<string, string>> | null
  } => ({
    locales: null,
    siteTree: null,
    sessionSettings: null,
    countries: null,
    errors: null,
    settings: null,
    productsUrl: null,
    servicesUrl: null,
    faqUrl: null,
    aboutUsUrl: null,
    translations: null,
  }),
  actions: {
    setLang(payload: string) {
      document.querySelector('html')!.setAttribute('lang', payload)
    },
    setProductsUrl(payload: string) {
      this.productsUrl = payload
    },
    setServicesUrl(payload: string) {
      this.servicesUrl = payload
    },
    setFaqUrl(payload: string) {
      this.faqUrl = payload
    },
    setAboutUsUrl(payload: string) {
      this.aboutUsUrl = payload
    },
    async setLocales() {
      this.locales = await getLocales()
    },
    async setSiteTree() {
      this.siteTree = listToTree(
        (await getSiteTreeSections()).filter((item) => item.visible),
        'parent_section_id',
      )
    },
    async setTranslations() {
      this.translations = await getTranslations()
      this.translateRouteAttributes()
    },
    translateRouteAttributes() {
      const proProductsRoute = this.router
        .getRoutes()
        .find((r) => r.name === PRO_PRODUCTS_ROUTE_NAME)

      if (!proProductsRoute?.meta) return

      proProductsRoute.meta = {
        ...proProductsRoute.meta,
        meta_description: __('products', 'pro-products-page-meta-description'),
        meta_title: __('products', 'pro-products-page-meta-title'),
        title: __('products', 'pro-products-page-title'),
      }
      this.router.removeRoute(PRO_PRODUCTS_ROUTE_NAME)
      this.router.addRoute(proProductsRoute)
    },
    async setSessionSettings() {
      this.sessionSettings = await getSessionSettings()
    },
    async putSessionSetting(data: {
      id: string
      value: string | boolean | number
    }) {
      await axios
        .put(`/${useLangStore().getLang()}/session-settings/${data.id}`, {
          id: data.id,
          value: data.value,
        })
        .catch((error) => (this.errors = setErrors(error)))
    },
    async setCountries() {
      this.countries = await getCountries([
        'currency',
        'default_locale',
        'blacklisted_products',
      ])
    },
    async setSettings() {
      this.settings = await getSettings()
    },
    getSettingByKey<T extends Setting['key']>(
      key: T,
    ): Extract<Setting, { key: T }> | undefined {
      if (!this.settings) return
      return this.settings.find((setting) => setting.key === key) as
        | Extract<Setting, { key: T }>
        | undefined
    },
  },
})
